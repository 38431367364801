import React from 'react';
import styled from 'styled-components';
import Container from '../layout/Container';
import Button from '../components/Button';
import { Link } from 'gatsby';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const PositionedContainer = styled(Container)`
  position: relative;
  padding-top: 0px;
`;

const Text = styled.h5`
  font-size: ${({ theme }) => theme.font.sizes.lg};
  font-family: ${({ theme }) => theme.font.families.title};
  margin-top: 0;

  color: ${({ theme }) => theme.colours.black};
  text-align: center;
  font-weight: 400;
  line-height: 130%;
  b {
    font-weight: 700;
  }

  ${({ theme }) => theme.breakpoint('medium')`
    font-size: ${theme.font.sizes.xl};
  `}
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const LoginExtraContentSection = () => {
  return (
    <Background>
      <PositionedContainer>
        <Text>
          You can view the content by heading to the agenda page below.
        </Text>
        <StyledLink to="/agenda">
          <StyledButton colourLevel="PRIMARY">
            See Conference Agenda
          </StyledButton>
        </StyledLink>
      </PositionedContainer>
    </Background>
  );
};

export default LoginExtraContentSection;
